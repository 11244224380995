<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('CustomerCreate')"
    @edit="(id) => onEdit('CustomerEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'Customers',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'Customers',
      tableCaption: 'Клиенты',
      breadcrumbs: [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Клиенты',
        },
      ],
      tableHeaders: [
        { text: 'ID', alias: 'id', order: 'id' },
        { text: 'Наименование', alias: 'name', order: 'name' },
        // { text: 'Папка', alias: 'folder', order: 'folders' },
        { text: 'Разрешен вход на help.1caero.ru', alias: 'allowHelpAeroAccess', order: 'allowHelpAeroAccess' },
        { text: '', alias: 'actions', order: 'actions' },
      ],
    };
  },
};
</script>
